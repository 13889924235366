import { useContext } from 'react';
import styled from 'styled-components';

import SiteContext from 'context/SiteContext';

import { BodyText } from 'components/atoms/Typography';
import { CloseIcon } from 'components/atoms/Icons';

const NotificationWrapper = styled.div`
    background: #1d1d1d;
    color: #fff;
    z-index: 101010;
    ${BodyText} {
        color: #fff;
    }
`;

const NotificationBar = () => {
    const {
        store: {
            notificationBar: { isOpen, content },
        },
        hideNotification,
    } = useContext(SiteContext);

    return isOpen && content ? (
        <NotificationWrapper className="fixed w-full py-1 text-center">
            <BodyText className="container relative">{content}</BodyText>
            <button
                className="absolute right-6 top-1/2 transform -translate-y-1/2"
                onClick={hideNotification}
            >
                <CloseIcon fill="currentColor" />
            </button>
        </NotificationWrapper>
    ) : null;
};

export default NotificationBar;
